
import store from "@/modules/adaptedStorage";

import { defineComponent } from "vue";
import ud from '@/modules/cyanUserData';
import CyanPageWrapper from '@/components/CyanPageWrapper.vue'
import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";
import cyanRequest from "@/modules/cyanRequest";
import { municipios } from "@/modules/cyanRegions";

export default defineComponent({
  name: "AgenteConteo",
  components: {
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    CyanPageWrapper
  },

  setup() {
    return {
    }
  },
  data() {
    return {
      votoActivo: {},
    };
  },
  computed: {
    convocatorias() {
      return ud('convocatoriasBU', {})
    },
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in municipios)) return "";
      return municipios[n].nombre + ", " + municipios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    estado() {
      return ud('bocaUrna');
    }

  },
  methods: {
    async addVoto(partido: any, convocatoria: any) {
     await this.alterVoto(partido, convocatoria, true);
    },

    async delVoto(partido: any, convocatoria: any) {
      await this.alterVoto(partido, convocatoria, false);
    },
    async alterVoto(partido: any, convocatoria: any, add: boolean) {

      const va = (this.votoActivo as any);

      if (typeof va[convocatoria] === "undefined")
        va[convocatoria] = {};

      if (typeof va[convocatoria][partido] === "undefined")
        va[convocatoria][partido] = 0;

      // Evitar doble clic (a mejorar)

      if (va[convocatoria][partido]) return;

      va[convocatoria][partido]++;

      // precargar el cambio

      const conteoDummy =  {
        /* eslint-disable @typescript-eslint/camelcase */
        centro_id: this.datosCentro.id,
        convocatoria_id: convocatoria,
        partido_id: partido,
        ok: true,
        /* eslint-enable @typescript-eslint/camelcase */
        votos: this.estado[convocatoria][partido] + (add ? 1 : -1)
      };

      store.commit("storeBocaUrna",conteoDummy);

      const r = await cyanRequest(
        "busl/" +
          (add ? "addBocaUrna" : "subBocaUrna") +
          "/" +
          convocatoria +
          "/" +
          partido,
        {
          needsAuth: true,
          isPost: true,
          silent: false,
          isModal: false,
        }
      );

      va[convocatoria][partido]--;

      if (r.ok) {
        store.commit( "storeBocaUrna", r);
      }
      else this.actualizarDatos();
    },

    actualizarDatos() {
      cyanRequest(
        "busl/bocaUrna/",
        {
          needsAuth: true,
          silent: true,
          isModal: false,
        }
      ).then(function (r) {
        if (r.ok) {
          store.commit("patchUserData", r);
        }
      });
    },
  },

  watch: {
  },
});
